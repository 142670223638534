import axios from 'axios';
import {getDataAccessHeaders, logout} from '../dataAccessHelper';


export async function register(registerData) {
    const result = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_HOST}/customer/register`, registerData, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
        throw err
    });
    return result?.data
};

export async function notifyCustomerCartCreation(customerId) {
    const result = await axios.post(`${process.env.REACT_APP_MIDDLEWARE_HOST}/customer/notify-cart-creation?customerId=${customerId}`, null, {
        headers: getDataAccessHeaders()
    })
    .catch(err => {
        if (err.status >= 401) {
          logout();
        }
    });
    return result.data
};

export async function getTaxCodeTypes(countryCode) {
    const result = await axios.get(`${process.env.REACT_APP_MIDDLEWARE_HOST}/customer/tax-code-types?countryCode=${countryCode}`, {
        headers: getDataAccessHeaders()
    })
    .catch(err => console.error(err));
    return result?.data
};