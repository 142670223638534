export function findArrayElement(arr, key) {
    return arr.find(el => el.key === key)
}

export function getUpdatedValues(event, previousValues) {
    let temp = {...previousValues};
    const value = {
        key: event.target.name,
        value: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        label: event.target.name,
    };
    return upsertValue(temp.values, value);
}

const upsertValue = (arr, value) => {
    const found = arr.some(el => el.key === value.key);
    if (!found) {
        arr.push(value);
    } else {
        arr = arr.map(el => {
            if (el.key === value.key) {
                return value;
            }
            return el;
        })
    }
    return arr;
}