import i18 from "../../lib/i18n";
import {scrollToElement} from "../../lib/helper/scrollToElement";

const t = i18.namespace("Validation");

export const VALIDATOR_KEY_REQUIRED = "REQUIRED";
export const VALIDATOR_KEY_MAX_LENGTH = "MAX_LENGTH";
export const VALIDATOR_KEY_MIN_LENGTH = "MIN_LENGTH";
export const VALIDATOR_KEY_MAX_LENGTH_DIGIT = "MAX_LENGTH_DIGIT";
export const VALIDATOR_KEY_MIN_LENGTH_DIGIT = "MIN_LENGTH_DIGIT";
export const VALIDATOR_KEY_EXACTLY_LENGTH = "EXACTLY_LENGTH";
export const VALIDATOR_KEY_EXACTLY_DIGITS_ONLY = "EXACTLY_DIGITS_ONLY";
export const VALIDATOR_KEY_EXACT_TAX_CODE_LENGTH = "EXACT_TAX_CODE_LENGTH";
export const VALIDATOR_KEY_COMPARE = "COMPARE";
export const VALIDATOR_KEY_DATE = "DATE";
export const VALIDATOR_KEY_EMAIL = "EMAIL";
export const VALIDATOR_KEY_ZIPCODE = "ZIPCODE";
export const VALIDATOR_KEY_PASSWORD = "PASSWORD";
export const VALIDATOR_PHONE_PREFIX = "PHONE_PREFIX";
export const VALIDATOR_DIGITS_ONLY = "DIGITS_ONLY";
export const VALIDATOR_KEY_SWEDISH_ZIPCODE = "SWEDISH_ZIPCODE";
export const VALIDATOR_GATEWAYID_FORMAT = "EXACTLY_MATCH_GATEWAYID_FORMAT"

export const dateValidation = (value) => {
  return !!value && Date.parse(value) <= Date.now() &&/(?:19|20)(?:(?:[13579][26]|[02468][048])-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))|(?:[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:29|30))|(?:(?:0[13578]|1[02])-31))))/.test(value) ? null : VALIDATOR_KEY_DATE;
}

export const emailValidation = (value) => {
  return !!value && /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) ? null : VALIDATOR_KEY_EMAIL
}

export const zipCodeValidation = (value, constraints) => {
  return (!value || value.length === constraints.length) && /^[0-9]*$/.test(value) ?  null : VALIDATOR_KEY_ZIPCODE;
}

export const passwordValidation = (value) => {
  return !!value && /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(value) ? null : VALIDATOR_KEY_PASSWORD
}

export const phonePrefixValidation = (value) => {
  return !!value && /^\+[0-9]{1,3}$/.test(value) ? null : VALIDATOR_PHONE_PREFIX
}

export const requiredValidator = (value) => {
  return !!value ? null : VALIDATOR_KEY_REQUIRED;
}

export const swedishZipCodeValidation = (value) => {
  return !!value && /^\d{3} \d{2}$/.test(value) ? null : VALIDATOR_KEY_SWEDISH_ZIPCODE;
}

export const minLengthDigitValidator = ((value, constraints) => {
  return (!value || value.length >= constraints.minLength) ? null : VALIDATOR_KEY_MIN_LENGTH_DIGIT;
})

export const maxLengthDigitValidator = ((value, constraints) => {
  return (!value || value.length <= constraints.maxLength) ? null : VALIDATOR_KEY_MAX_LENGTH_DIGIT;
})

export const minLengthValidator = ((value, constraints) => {
  return (!value || value.length >= constraints.minLength) ? null : VALIDATOR_KEY_MIN_LENGTH;
})

export const maxLengthValidator = ((value, constraints) => {
  return (!value || value.length <= constraints.maxLength) ? null : VALIDATOR_KEY_MAX_LENGTH;
})

export const exactlyLengthValidator = ((value, constraints) => {
  return (!value || value.length === constraints.exactlyLength) ? null : VALIDATOR_KEY_EXACTLY_LENGTH;
})

export const exactlyDigitsOnlyValidator = ((value, constraints) => {
  return (!value || (value.length === constraints.exactlyLength && /^\d+$/.test(value))) ? null : VALIDATOR_KEY_EXACTLY_DIGITS_ONLY;
})

export const exactTaxCodeLengthValidator = ((value, constraints) => {
  return (!value || value.length === constraints.exactlyLength) ? null : VALIDATOR_KEY_EXACT_TAX_CODE_LENGTH;
})

export const digitsOnlyValidator = ((value, constraints) => {
  return (!value || /^\d+$/.test(value)) ? null : VALIDATOR_DIGITS_ONLY;
})

export const gatewayFormatValidator = ((value, constraints) => {
  return (!value || /^B[0-9]{4}-[0-9]{5}$/.test(value)) ? null : VALIDATOR_GATEWAYID_FORMAT;
})

export const compareValidator = ((value, constraints, currentStepValues) => {
  const compareControlValue = getFormItemValue(constraints.compareControl, currentStepValues);
  return (!value || !compareControlValue || value === compareControlValue) ? null : VALIDATOR_KEY_COMPARE;
})

export function getFormItemValue(key, currentStepValues) {
  // TODO: the Array.isArray check is just a quick fix to prevent exceptions in the accountInformation Step
  return key
      && Array.isArray(currentStepValues)
    && currentStepValues?.find(controlState => controlState.key === key)?.value;
}

export const ValidatorMap = {
  [VALIDATOR_KEY_REQUIRED]: requiredValidator,
  [VALIDATOR_KEY_MAX_LENGTH]: maxLengthValidator,
  [VALIDATOR_KEY_MIN_LENGTH]: minLengthValidator,
  [VALIDATOR_KEY_EXACTLY_LENGTH]: exactlyLengthValidator,
  [VALIDATOR_KEY_EXACTLY_DIGITS_ONLY]: exactlyDigitsOnlyValidator,
  [VALIDATOR_KEY_EXACT_TAX_CODE_LENGTH]: exactTaxCodeLengthValidator,
  [VALIDATOR_KEY_COMPARE]: compareValidator,
  [VALIDATOR_KEY_DATE]: dateValidation,
  [VALIDATOR_KEY_EMAIL]: emailValidation,
  [VALIDATOR_KEY_ZIPCODE]: zipCodeValidation,
  [VALIDATOR_KEY_PASSWORD]: passwordValidation,
  [VALIDATOR_PHONE_PREFIX]: phonePrefixValidation,
  [VALIDATOR_KEY_SWEDISH_ZIPCODE]: swedishZipCodeValidation,
  [VALIDATOR_DIGITS_ONLY]: digitsOnlyValidator,
  [VALIDATOR_GATEWAYID_FORMAT]: gatewayFormatValidator,
  [VALIDATOR_KEY_MIN_LENGTH_DIGIT]: minLengthDigitValidator,
  [VALIDATOR_KEY_MAX_LENGTH_DIGIT]: maxLengthDigitValidator

}

export function getValidators(keys) {
  return Array.isArray(keys) ? keys.map(key => {
    return ValidatorMap[key];
  }) : [];
}

export function isOptional(keys) {
  return Array.isArray(keys) ? !keys.includes(VALIDATOR_KEY_REQUIRED) : true;
}

export const showValidation = (invalid, touched, serverError) => {
  return serverError || (invalid && touched);
}

export function getValidationMessage(key, params, validationMessages, backendValidationMsg) {
  if (backendValidationMsg) {
    return backendValidationMsg;
  }

  if (validationMessages && validationMessages[key]) {
    return i18.interpolate(validationMessages[key], params);
  }

  return t(`common.${key}`, params);
}


const SEL_INVALID_CONTROLS = ".form-control.is-invalid";

export function scrollToFirstInvalidControl() {
  setTimeout(() => {scrollToElement(SEL_INVALID_CONTROLS)}, 0);

}

